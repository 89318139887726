<template>
  <div class="agreement-box">
    <van-nav-bar :title="$t('agreement.termsOfUse')"
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'">
      <i slot="left" class="iconfont icon-jiantou" @click="$router.back()" size="50px"
        :color="!$isLinkGame ? '#ffd400' : '#fff'"></i>
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'">{{ $t('agreement.termsOfUse') }}</span>
      </template>
    </van-nav-bar>
    <div v-html="agreement" class="agreement-html"></div>
  </div>
</template>

<script>
// 导入导航栏组件
import Navbar from '@/components/Navbar.vue'
import '../my.less'
import { sendSms, login } from '@/api/use.js'
import request from '@/utils/request'

export default {
  name: '',
  components: {
    'nav-bar': Navbar
  },
  data () {
    return {
      agreement: ''
    }
  },
  mounted () { },
  created () {
    this.agreement =
      `<div style="text-align:center;font-size:16px;">${this.$t('agreement.loadingProtocol')}</div>`
    this.getConfig()
  },
  methods: {
    async getConfig () {
      const {
        data: { data, ret }
      } = await request({
        method: 'get',
        url: 'other/config',
        params: { act: 'about', refresh: this.isLoading === true ? 1 : 0 }
      })
      if (ret === 1) {
        this.agreement = data.agreement
      }
    }
  }
}
</script>

<style lang="less" scoped>
.agreement-box {
  width: 100%;
  min-height: 100%;
  background: #fff;
}

.agreement-html {
  padding: 20px;

  /deep/ p {
    font-size: 30px;
    margin-bottom: 30px;
  }
}
</style>
